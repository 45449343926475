.menu {
  height: inherit;
}
.menu :global(.ant-menu-submenu-arrow) {
  right: 0;
  display: inline-block;
  transform: rotate(90deg);
}
.menu :global(.ant-menu-submenu-open .ant-menu-submenu-arrow) {
  transform: rotate(-90deg);
}
.menu :global(.ant-menu-item::after),
.menu :global(.ant-menu-submenu::after) {
  display: none;
}
