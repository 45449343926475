.checkbox {
  color: inherit;
}
.checkbox :global(.ant-checkbox) {
  top: 0;
}
.checkbox :global(.ant-checkbox .ant-checkbox-inner) {
  border-color: var(--main-gray-5);
}
.checkbox-color-orange :global(.ant-checkbox-checked .ant-checkbox-inner),
.checkbox-color-orange:global(
    .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover
      .ant-checkbox-checked:not(.ant-checkbox-disabled)
      .ant-checkbox-inner
  ) {
  background-color: var(--main-orange-8);
  border-color: var(--main-orange-8);
}
.checkbox :global(.ant-checkbox .ant-checkbox-checked::after) {
  border-color: var(--main-orange-8);
  border-radius: 2px;
}
.checkbox :global(.ant-checkbox-disabled + span) {
  color: var(--main-gray-4);
}
.checkbox :global(.ant-checkbox-disabled .ant-checkbox-inner) {
  background-color: var(--main-gray-2);
  border-color: var(--main-gray-4);
}
.checkbox :global(.ant-checkbox-disabled::after) {
  border-color: var(--main-gray-4);
  border-radius: 2px;
}
.checkbox :global(.ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after) {
  border-color: white;
}
:global(.ant-checkbox-indeterminate .ant-checkbox-inner),
:global(.ant-checkbox-indeterminate .ant-checkbox-inner::after),
:global(.ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner),
:global(.ant-checkbox-input:focus + .ant-checkbox-inner) {
  border-color: var(--main-orange-8);
}
:global(.ant-checkbox-wrapper:not(.ant-checkbox-disabled):hover .ant-checkbox-inner) {
  border-color: var(--main-gray-5);
}
:global(
    .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover
      .ant-checkbox-checked:not(.ant-checkbox-disabled)
      .ant-checkbox-inner
  ),
:global(
    .ant-checkbox-wrapper-checked:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-inner
  ),
:global(.ant-checkbox-checked:not(.ant-checkbox-disabled):hover .ant-checkbox-inner),
:global(.ant-checkbox-checked .ant-checkbox-inner) {
  background-color: var(--main-orange-8);
  border-color: transparent;
}
.checkbox-size-small :global(.ant-checkbox + span) {
  font-size: 12px;
  line-height: 18px;
}
.checkbox-size-middle :global(.ant-checkbox) {
  top: 3px;
  align-self: baseline;
}
.checkbox-size-middle :global(.ant-checkbox + span) {
  position: relative;
  top: 0;
  font-size: 14px;
  line-height: 22px;
}
.checkbox-size-large:global(.ant-checkbox-wrapper) {
  align-items: center;
}
.checkbox-size-large :global(.ant-checkbox) {
  width: 20px;
  height: 20px;
  font-size: 16px;
}
.checkbox-size-large :global(.ant-checkbox .ant-checkbox-inner) {
  width: 20px;
  height: 20px;
}
.checkbox-size-large :global(.ant-checkbox + span) {
  padding-inline: 6px;
  font-size: 16px;
  line-height: 24px;
  color: var(--dark);
}
.checkbox-size-large:global(
    .ant-checkbox-wrapper.ant-checkbox-wrapper-in-form-item input[type="checkbox"]
  ) {
  width: 20px;
  height: 20px;
}
.checkbox-size-large :global(.ant-checkbox .ant-checkbox-inner::after) {
  inset-inline-start: 30%;
}
.checkbox-align-middle {
  align-items: center;
}
.checkbox-align-top {
  align-items: flex-start;
}
.checkbox-align-bottom {
  align-items: flex-end;
}
