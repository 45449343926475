.alert--primary:global(.ant-alert-error) {
  background-color: var(--orange-1);
  border: 1px solid var(--main-orange-5);
  border-radius: 4px;
}
.alert--primary:global(.ant-alert-error .ant-alert-icon) {
  color: var(--main-orange-9);
}
.alert--pending:global(.ant-alert) {
  padding: 16px;
  line-height: 20px;
  color: var(--gray-6);
  background-color: var(--states-warning-2);
  border: none;
  border-radius: 4px;
}
.alert--pending:global(.ant-alert .ant-alert-description) {
  color: var(--gray-6);
}
.alert--pending:global(.ant-alert-with-description .ant-alert-icon) {
  margin-inline-end: 10px;
}
.alert--note:global(.ant-alert) {
  padding: 8px;
  background-color: var(--main-gray-2);
  border: none;
}
.alert--note:global(.ant-alert .ant-alert-description) {
  font-size: var(--font-size-sm);
  line-height: 16px;
}
.alert--note:global(.ant-alert-with-description .ant-alert-icon) {
  margin-inline-end: 4px;
}
