.basket-service-providers {
  position: relative;
  flex: 0;
  width: 100%;
  padding: 24px 0;
  background: var(--main-gray-2);
}
.basket-service-providers__list {
  display: flex;
  gap: 16px;
}
.basket-service-providers__card {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  min-height: 46px;
  background-color: var(--white);
}
