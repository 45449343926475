.basket-checkout-credit-cards-add {
  position: relative;
  justify-content: center;
  opacity: 0;
  animation: fade-in 0.8s ease-in-out;
  animation-fill-mode: forwards;
}
.basket-checkout-credit-cards-add__header {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  width: 100%;
}
.basket-checkout-credit-cards-add__card-details {
  margin: 20px 0 0;
}
.basket-checkout-credit-cards-add__card-details :global(.adyen-checkout-form-instruction) {
  display: none;
}
.basket-checkout-credit-cards-add__card-details :global(.adyen-checkout__card__brands) {
  position: absolute;
  top: 6px;
  right: 0;
}
.basket-checkout-credit-cards-add__card-details
  :global(.adyen-checkout__card__brands__brand-wrapper) {
  width: 45px;
  height: 20px;
}
.basket-checkout-credit-cards-add__card-details :global(.adyen-checkout__store-details) {
  padding-left: 0;
  margin: 0;
  border: 0;
}
.basket-checkout-credit-cards-add__card-details :global(.adyen-checkout__checkbox__input) {
  width: 20px;
  height: 20px;
  border-radius: 2px;
}
.basket-checkout-credit-cards-add__card-details :global(.adyen-checkout__card__holderName) {
  margin-bottom: 0;
}
.basket-checkout-credit-cards-add__card-details :global(.adyen-checkout__label__text) {
  font-weight: 500;
}
.basket-checkout-credit-cards-add__card-details
  :global(.adyen-checkout__checkbox__input:checked + .adyen-checkout__checkbox__label::after) {
  background-color: var(--corporate-orange);
  border-color: var(--corporate-orange);
}
.basket-checkout-credit-cards-add__card-details
  :global(
    .adyen-checkout__checkbox__input:hover:not(:focus) + .adyen-checkout__checkbox__label::after
  ),
.basket-checkout-credit-cards-add__card-details
  :global(.adyen-checkout__checkbox__input:focus + .adyen-checkout__checkbox__label::after) {
  border-color: var(--gray-45);
  box-shadow: 0 0 0 2px var(--gray-45);
}
.basket-checkout-credit-cards-add__card-details
  :global(.adyen-checkout__button.adyen-checkout__button--loading) {
  background: var(--primary-blue-6);
}
.basket-checkout-credit-cards-add__card-details
  :global(.adyen-checkout__button.adyen-checkout__button--pay) {
  margin-top: 0;
  font-family: var(--font-base);
  animation: fade-in 1.4s;
  animation-fill-mode: forwards;
  img {
    display: none;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
