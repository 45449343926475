.cell-text {
  height: inherit;
}
.cell-text__prefix {
  width: auto;
}
.cell-text__postfix {
  padding-left: 4px;
}
.disabled {
  color: var(--gray-4);
}
.skeleton {
  width: 100%;
}
.skeleton :global .ant-skeleton-input {
  width: 100%;
  min-width: 90px;
}
