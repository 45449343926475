.breadcrumbs {
  height: inherit;
}
.breadcrumbs:global(.ant-breadcrumb a) {
  color: var(--primary-blue-8);
}
.breadcrumbs:global(.ant-breadcrumb ol li) {
  display: inline-flex;
  align-items: center;
}
.breadcrumbs:global(.ant-breadcrumb .ant-breadcrumb-link) {
  font-size: 12px;
}
.breadcrumbs:global(.ant-breadcrumb) {
  & ol {
    padding: 0;
    margin: 0;
    list-style: none;
  }
  & li {
    display: inline-flex;
  }
  & a {
    height: auto;
    color: var(--primary-blue-8);
    &:hover {
      background-color: transparent;
    }
  }
  &.breadcrumbs--mb-24 {
    margin-bottom: 24px;
  }
  &.breadcrumbs--mb-32 {
    margin-bottom: 32px;
  }
}
.breadcrumbs:global(.ant-breadcrumb .ant-breadcrumb-separator) {
  display: inline-block;
  margin-inline: 12px;
  font-size: 12px;
  color: var(--main-gray-35);
  vertical-align: middle;
}
.breadcrumbs:global(.ant-breadcrumb a:hover) {
  color: var(--primary-blue-6);
  text-decoration: none;
  background: none;
}
.breadcrumbs:global(.ant-breadcrumb li:last-child a) {
  color: var(--primary-blue-8);
}
.breadcrumbs:global(.ant-breadcrumb li:last-child a:hover) {
  color: var(--primary-blue-7);
}
.breadcrumbs :global(.anticon-down) {
  display: none;
}
