.tag:global(.ant-tag) {
  position: relative;
  display: flex;
  align-items: center;
  width: fit-content;
  height: inherit;
  padding-right: 8px;
  padding-left: 8px;
  margin-inline-end: 0;
  font-size: 14px;
  font-weight: 400;
  color: inherit;
}
.tag--has-icon:global(.ant-tag) {
  padding-right: 4px;
}
.tag-type-default:global(.ant-tag) {
  background-color: var(--main-gray-2);
  border-color: var(--main-gray-35);
  & :global(.anticon) {
    color: var(--neutral-75);
  }
}
.tag-type-success:global(.ant-tag) {
  background-color: var(--success-default-2);
  border-color: var(--success-default);
  & :global(.anticon) {
    color: var(--success-default);
  }
}
.tag-type-info:global(.ant-tag) {
  background-color: var(--primary-blue-1);
  border-color: var(--states-info-table);
  & :global(.anticon) {
    color: var(--states-info-table);
  }
}
.tag-type-alert:global(.ant-tag) {
  background-color: var(--states-warning-2);
  border-color: var(--warning-default);
  & :global(.anticon) {
    color: var(--warning-default);
  }
}
.tag-type-warning:global(.ant-tag) {
  background-color: var(--orange-2);
  border-color: var(--corporate-orange);
  & :global(.anticon) {
    color: var(--corporate-orange);
  }
}
.tag-type-error:global(.ant-tag) {
  background-color: var(--cta-1);
  border-color: var(--error-default);
  & :global(.anticon) {
    color: var(--error-default);
  }
}
