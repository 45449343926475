.checkbox {
  color: inherit;
}
.checkbox :global .ant-checkbox-inner {
  border-color: var(--blue-5);
  border-radius: 2px;
}
.checkbox :global .ant-checkbox {
  color: var(--main-gray-7);
  border-radius: 2px;
}
.checkbox :global .ant-checkbox-checked .ant-checkbox-inner {
  background: var(--main-orange-8);
  border-color: var(--main-orange-8);
}
.checkbox :global .ant-checkbox + span {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: var(--main-gray-7);
}
.checkbox :global .ant-checkbox-checked::after {
  border-color: var(--main-orange-8);
  border-radius: 2px;
}
.checkbox :global .ant-checkbox-disabled .ant-checkbox-inner {
  background: var(--main-gray-2);
  border-color: var(--main-gray-4);
}
.checkbox :global .ant-checkbox:hover .ant-checkbox-inner {
  border-color: var(--main-orange-8);
}
.checkbox :global .ant-checkbox-disabled::after {
  border-color: var(--main-gray-4);
  border-radius: 2px;
}
.checkbox :global .ant-checkbox-disabled + span {
  color: var(--main-gray-4);
}
.checkbox :global .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: var(--white);
}
.checkbox :global .ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: var(--main-orange-8);
}
