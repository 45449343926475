.order-status-group {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.order-status-group__header,
.order-status-group__item {
  display: flex;
  flex-direction: row;
  gap: 4px;
}
.order-status-skeleton {
  width: 50%;
  max-height: 12px;
  margin: 0 0 2px;
}
