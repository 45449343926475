.order {
  width: 100%;
}
.order__totals {
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 0;
  margin: 0;
  list-style: none;
}
.orders-breadcrumbs:global(.ant-breadcrumb) {
  margin: 0 0 24px;
}
.order-status {
  padding: 8px 0 24px;
}
