.actions {
  height: inherit;
}
.actions__col {
  flex: 1 1 50%;
}
.stepper-actions__fit-button {
  min-width: fit-content;
}
.stepper-actions__fit-button:global(.ant-btn-default) {
  color: var(--primary-blue-8);
  border-color: var(--primary-blue-8);
}
