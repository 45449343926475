.navbar {
  & :global .ant-menu-horizontal {
    display: flex;
    justify-content: center;
  }
  & :global .ant-menu-dark,
  & :global .ant-menu-dark .ant-menu-sub {
    color: var(--blue-2);
    background: var(--dark);
  }
  & :global .ant-menu-sub.ant-menu-vertical {
    padding: 8px;
    border-radius: 4px;
  }
  & :global .ant-menu-sub.ant-menu-vertical .ant-menu-item.ant-menu-item-only-child {
    margin: 0;
    font-weight: 600;
    border-bottom: 1px solid var(--main-gray-85);
  }
  & :global .ant-menu-sub.ant-menu-vertical .ant-menu-item.ant-menu-item-only-child:last-child {
    border-bottom: none;
  }
  & :global .ant-menu-horizontal > .ant-menu-submenu > .ant-menu-submenu-title {
    padding-right: 18px;
  }
  & :global .ant-menu-title-content {
    display: flex;
  }
  & :global .ant-menu-vertical .ant-menu-title-content {
    display: flex;
    align-items: center;
    height: 100%;
  }
  & :global .ant-menu-dark.ant-menu-horizontal > .ant-menu-item,
  & :global .ant-menu-dark.ant-menu-horizontal > .ant-menu-submenu {
    display: flex;
    align-items: center;
    padding: 6px 20px;
    font-size: 14px;
    font-weight: 600;
    line-height: 34px;
    color: var(--blue-2);
    background: var(--dark);
  }
}
